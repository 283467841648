
import { defineComponent, ref, computed, reactive } from 'vue';
import swal from 'sweetalert2';
import Q from 'q';
import { OrdersFilter } from '@/models/Order';
import { generalStore } from '@/store';
import router from '@/router';
import { OrderStatus } from '@/models/Enums';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import useOrder from '@/modules/useOrder';
import { api } from '@/services/Api';
import { DateRange } from '@/models/Interfaces';
import { CouponFilter, Currency } from '@/models/Provider';

export default defineComponent({
    components: { Calendar, Multiselect },
    name: 'CouponFilter',
    props: {
        businessClientId: {
            type: Number,
            required: false
        }
    },
    data() {
        return {};
    },
    async setup(props) {
        const { convertDate } = useOrder();
        const filter = ref<CouponFilter>(new CouponFilter());
        filter.value = generalStore.getters.getCouponFilter || new CouponFilter();

        if (props.businessClientId) {
            filter.value.clubId = props.businessClientId.toString();
            filter.value.fromBusinessClient = true;
        }

        const productResult = await api.getProducts({ productName: '', typeId: '', status: null, keyword: '' });
        const products = productResult.data?.products || [];

        const clients = await generalStore.dispatch('loadBusinessClients');

        const priceTypes = await generalStore.dispatch('getPriceTypes');
        const currencies = computed<Currency[]>(() => generalStore.getters.currencies);
        const discountTypes = computed(() => [...currencies.value, { id: '', symbol: '%' }]);

        const activeDateRange = reactive(new DateRange());
        const bookingDateRange = reactive(new DateRange());

        if (filter.value?.fromDate) {
            activeDateRange.fromDate = new Date(filter.value?.fromDate);
        }
        if (filter.value?.toDate) {
            activeDateRange.toDate = new Date(filter.value?.toDate);
        }
        if (filter.value?.createFromDate) {
            bookingDateRange.fromDate = new Date(filter.value?.createFromDate);
        }
        if (filter.value?.createToDate) {
            bookingDateRange.toDate = new Date(filter.value?.createToDate);
        }

        function ResetFilter() {
            generalStore.commit('saveCouponFilter', new CouponFilter());
            router.go(-1);
        }

        function Close() {
            router.go(-1);
        }

        function Search() {
            if (filter.value) {
                if (!filter.value.discountAmount) {
                    filter.value.discountAmount = null;
                }
                if (activeDateRange.fromDate) {
                    filter.value.fromDate = convertDate(activeDateRange.fromDate);
                }
                if (activeDateRange.toDate) {
                    filter.value.toDate = convertDate(activeDateRange.toDate);
                }
                if (bookingDateRange.fromDate) {
                    filter.value.createFromDate = convertDate(bookingDateRange.fromDate);
                }
                if (bookingDateRange.toDate) {
                    filter.value.createFromDate = convertDate(bookingDateRange.toDate);
                }
            }
            filter.value.keyword = '';
            generalStore.commit('saveCouponFilter', filter.value);
            router.go(-1);
        }
        return {
            filter,
            ResetFilter,
            Close,
            Search,
            products,
            clients,
            priceTypes,
            discountTypes,
            activeDateRange,
            bookingDateRange
        };
    }
});
